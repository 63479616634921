.garden {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Align center horizontally */
    align-items: center; /* Align center vertically */
    margin: '2rem 0';
  
    .gardenImg {
      margin: 20px;
      padding: 0;
      box-sizing: border-box;
  
      @media (min-width: 80%) {
        width: calc(20% - 10px); /* 10px is for margin between images */
      }
    }
  }

  .carousel-ctn {
    margin: 3rem 0;
    width: 100%;
  }
  