.age-gate-container {
  padding: 50px;
}
.age-gate-container .age-gate {
  background-color: unset;
  box-shadow: unset;
}
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30%;
}
.date-inputs .input-container input {
  background-color: unset;
  text-align: center;
  border: none;
  border-radius: unset;
  border-bottom: 2px solid #000; /* Add bottom border */
  font-family: inter;
  font-weight: 700;
  font-size: 2em;
}
.age-gate label{
  color: #6d7b88;
}

//terms privacy component
.terms-and-privacy-container .mutli-link {
  display: flex;
  justify-content: space-between;
  padding-top: 2em;
}
.tp-link {
  font-family: Inter;
  font-weight: 600;
  color: #0071ce;
}
.terms-and-privacy-container .single-link {
  padding-top: 2em;
  display: block;
  font-family: Inter;
  font-weight: 600;
  color: #0071ce;
}

@media screen and (max-width: 500px) {
  .age-gate-container {
    padding: 0 !important;
  }
  .date-inputs input {
    padding: 0 !important;
  }
  .tp-link {
    font-size: 14px;
  }
  .single-link {
    font-size: 14px;
  }
}